import styled from "@emotion/styled";
import React from "react";
import hero from "./images/hero.png";
import technicalApparel from "./images/technicalApparel.png";

const LandingPage = () => {
  return (
    <Wrapper>
      <HeroImage src={hero} />
      <SmallerImage src={technicalApparel} />
    </Wrapper>
  );
};

const Wrapper = styled.div({
  height: `calc(100vh - 60px)`,
  margin: "0 auto",
  maxWidth: "700px",
  padding: "0 32px",
  marginTop: "60px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const HeroImage = styled.img({
  width: "100%",
});

const SmallerImage = styled.img({
  width: "70%",
});

export default LandingPage;
