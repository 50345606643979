import styled from "@emotion/styled";
import React from "react";
import drybackpack_25l from "./images/25l_drybackpack.jpg";
import drybag_25l from "./images/25l_drybag.jpg";
import drybag_45l from "./images/45l_bag.jpg";
import drybag_65l from "./images/65l_bag.jpg";
import adaptive_ocean_hood from "./images/adaptive_ocean_hood.jpg";
import adaptive_superwarm from "./images/adaptive_superwarm.jpg";
import aroshell_winter_hat from "./images/aroshell_winter_hat.jpg";
import avlare_beanie from "./images/avlare_beanie.jpg";
import avlare_gaiter from "./images/avlare_gaiter.jpg";
import buckle from "./images/buckle.jpg";
import coat_1 from "./images/coat_1.jpg";
import cst_500_men_jacket from "./images/cst_500_men_jacket.jpg";
import cst_500_sal from "./images/cst_500_sal.jpg";
import cst_500_smock from "./images/cst_500_smock.jpg";
import cst_500_women_jacket from "./images/cst_500_women_jacket.jpg";
import dinghy_reziseal from "./images/dinghy_reziseal.jpg";
import dinghy_smock from "./images/dinghy_smock.jpg";
import drybag_6l from "./images/drybag_6l.jpg";
import drysuit from "./images/drysuit.jpg";
import fuze_upper from "./images/fuze_upper.jpg";
import gaiter from "./images/gaiter.jpg";
import gaiter_2 from "./images/gaiter_2.jpg";
import glove_g2 from "./images/glove_g2.jpg";
import glove_superwarm from "./images/glove_superwarm.jpg";
import glove_tactical from "./images/glove_tactical.jpg";
import hydrobase from "./images/hydrobase.jpg";
import hydrophobic_fleece from "./images/hydrophobic_fleece.jpg";
import ins_100_men from "./images/ins_100_men.jpg";
import ins_100_women from "./images/ins_100_women.jpg";
import ins_200_men from "./images/ins_200_men.jpg";
import ins_200_sal from "./images/ins_200_sal.jpg";
import ins_200_women from "./images/ins_200_women.jpg";
import kollition_cut from "./images/kollition_cut.jpg";
import microfleece_v_pant from "./images/microfleece_v_pant.jpg";
import microfleece_v_skiff from "./images/microfleece_v_skiff.jpg";
import microfleece_v_top from "./images/microfleece_v_top.jpg";
import ofs_700 from "./images/ofs_700.jpg";
import ofs_700_jacket from "./images/ofs_700_jacket.jpg";
import ofs_700_sal from "./images/ofs_700_sal.jpg";
import ofs_800_jacket from "./images/ofs_800_jacket.jpg";
import ofs_800_jacket_2 from "./images/ofs_800_jacket_2.jpg";
import ofs_800_reziseal from "./images/ofs_800_reziseal.jpg";
import ofs_800_sal from "./images/ofs_800_sal.jpg";
import ofs_800_smock from "./images/ofs_800_smock.jpg";
import p2_pfd from "./images/p2_pfd.jpg";
import pfd_050 from "./images/pfd_050.jpg";
import puffer_cell from "./images/puffer_cell.jpg";
import puffer_cell_men from "./images/puffer_cell_men.jpg";
import puffer_tetra from "./images/puffer_tetra.jpg";
import puffer_ttx from "./images/puffer_ttx.jpg";
import puffer_xeflex from "./images/puffer_xeflex.jpg";
import reef_cutter from "./images/reef_cutter.jpg";
import superwarm_v_skiff from "./images/superwarm_v_skiff_.jpg";
import superwarm_v_steamer from "./images/superwarm_v_steamer_.jpg";
import superwarm_v_top from "./images/superwarm_v_top.jpg";
import transfer_top from "./images/transfer_top.jpg";
import z_cru_jacket from "./images/z_cru_jacket.jpg";

const tiles = [
  drybag_6l,
  drybackpack_25l,
  drybag_25l,
  drybag_45l,
  drybag_65l,
  adaptive_ocean_hood,
  adaptive_superwarm,
  aroshell_winter_hat,
  avlare_beanie,
  avlare_gaiter,
  buckle,
  coat_1,
  cst_500_men_jacket,
  cst_500_sal,
  cst_500_smock,
  cst_500_women_jacket,
  dinghy_reziseal,
  dinghy_smock,
  drysuit,
  fuze_upper,
  gaiter,
  gaiter_2,
  glove_g2,
  glove_superwarm,
  glove_tactical,
  hydrobase,
  hydrophobic_fleece,
  ins_100_men,
  ins_100_women,
  ins_200_men,
  ins_200_sal,
  ins_200_women,
  kollition_cut,
  microfleece_v_pant,
  microfleece_v_skiff,
  microfleece_v_top,
  ofs_700_jacket,
  ofs_700_sal,
  ofs_700,
  ofs_800_jacket_2,
  ofs_800_jacket,
  ofs_800_reziseal,
  ofs_800_sal,
  ofs_800_smock,
  p2_pfd,
  pfd_050,
  puffer_cell_men,
  puffer_cell,
  puffer_tetra,
  puffer_ttx,
  puffer_xeflex,
  reef_cutter,
  superwarm_v_skiff,
  superwarm_v_steamer,
  superwarm_v_top,
  transfer_top,
  z_cru_jacket,
];

const Tiles = () => {
  return (
    <Wrapper>
      <TilesContainer>
        {tiles.map((image) => (
          <Tile src={image} />
        ))}
      </TilesContainer>
    </Wrapper>
  );
};

const Tile = styled.img({
  width: "250px",
});

const TilesContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
  flexWrap: "wrap",
});

const Wrapper = styled.div({
  margin: "0 auto",
  maxWidth: "1400px",
  padding: "0 32px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export default Tiles;
